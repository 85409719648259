import { googleAnalyticsId } from '../config/google-analytics.config';

/**
 * Sets a temporary function that can be used while GA is loading.
 */
const createTemporaryGAFn = () => {
  if (!window.gtag) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer?.push(arguments);
    };
  }
};

const createTracker = (trackingId: string) => {
  window.gtag?.('config', trackingId);
};
export const createClientTracker = (id: string) => {
  if (!window.gtag) {
    createTemporaryGAFn();
  }
  createTracker(id);
};

let initialised = false;

export const injectGAScript = () => {
  if (initialised) return;

  createTemporaryGAFn();

  window.gtag?.('js', new Date());
  window.gtag?.('config', googleAnalyticsId);

  const gaScript = document.createElement('script');
  gaScript.type = 'text/javascript';
  gaScript.async = true;
  gaScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
  );

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(gaScript);

  initialised = true;
};
